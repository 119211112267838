<template>
  <div class="px-8 flex flex-col items-center pt-20">
    <h1 class="text-center font-medium">Ingredients</h1>

    <div class="border bg-primary rounded p-1 mt-2">
      <input placeholder="Filter" v-model="filter" class="px-2 rounded w-full">
    </div>

    <div class="table w-full max-w-lg rounded mt-4">
      <div class="table-row border bg-primary p-2">
        <div class="table-cell p-2">
          <input placeholder="Singular" v-model="singular" class="px-2 rounded w-full">
        </div>
        <div class="table-cell p-2">
          <input placeholder="Plural" v-model="plural" class="px-2 rounded w-full">
        </div>
        <div class="table-cell p-2">
          <select class="rounded px-2 w-full" v-model="type">
            <option value="">-</option>
            <option v-for="type in ingredientTypes" :key="type" :value="type">{{ type }}</option>
          </select>
        </div>
        <div class="table-cell p-2">
          <div @click="newIngredient" class="cursor-pointer">
            <font-awesome-icon icon="check" />
          </div>
        </div>
        <div class="table-cell"></div>
      </div>
      <div
        v-for="(ingredient, index) of ingredientsFiltered" :key="ingredient.id"
        class="table-row bg-primary p-2 rounded"
        :class="{ 'bg-opacity-50': (index - 1) % 2 }"
      >
        <div class="table-cell p-2">
          <input v-if="edit.id === ingredient.id" class="px-2 rounded w-full" type="text" v-model="edit.singular">
          <div v-else class="px-2">{{ ingredient.singular }}</div>
        </div>
        <div class="table-cell p-2">
          <input v-if="edit.id === ingredient.id" class="px-2 rounded w-full" type="text" v-model="edit.plural">
          <div v-else class="px-2">{{ ingredient.plural }}</div>
        </div>
        <div class="table-cell p-2">
          <select v-if="edit.id === ingredient.id" class="px-2 rounded w-full" v-model="edit.type">
            <option value="">-</option>
            <option v-for="type in ingredientTypes" :key="type" :value="type">{{ type }}</option>
          </select>
          <div v-else class="px-2">{{ ingredient.type || '-' }}</div>
        </div>
        <div class="table-cell p-2">
          <font-awesome-icon v-if="edit.id === ingredient.id" icon="check" class="cursor-pointer" @click="saveIngredient()" />
          <font-awesome-icon v-else icon="pen" class="cursor-pointer" @click="editIngredient(ingredient)" />
        </div>
        <div class="table-cell p-2">
          <font-awesome-icon icon="trash-alt" class="cursor-pointer" @click="deleteIngredient(ingredient.id)"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import http from '../../http'

export default {
  data () {
    return {
      ingredientTypes: [],
      ingredients: [],

      filter: '',

      edit: {
        id: null,
        singular: '',
        plural: '',
        type: ''
      },

      singular: '',
      plural: '',
      type: ''
    }
  },
  computed: {
    ingredientsFiltered () {
      const copy = this.ingredients.slice()

      copy.sort((a, b) => {
        const textA = a.singular.toLowerCase()
        const textB = b.singular.toLowerCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })

      return copy.filter(ingredient => {
        return ingredient.singular.includes(this.filter) || ingredient.plural.includes(this.filter)
      })
    }
  },
  mounted () {
    this.getIngredientTypes()
    this.getIngredients()
  },
  methods: {
    async getIngredientTypes () {
      const res = await http.get('/api/ingredient-types')

      this.ingredientTypes = res.data
    },
    async getIngredients () {
      const res = await http.get('/api/ingredients')

      this.ingredients = res.data
    },
    editIngredient (ingredient) {
      this.edit.id = ingredient.id
      this.edit.singular = ingredient.singular
      this.edit.plural = ingredient.plural
      this.edit.type = ingredient.type
    },
    async saveIngredient () {
      await http.post('/api/ingredients', this.edit)

      await this.getIngredients()

      this.edit.id = null
    },
    async newIngredient () {
      await http.post('/api/ingredients', {
        singular: this.singular,
        plural: this.plural,
        type: this.type
      })

      await this.getIngredients()

      this.singular = ''
      this.plural = ''
      this.type = ''
    },
    async deleteIngredient (id) {
      await http.delete(`/api/ingredients/${id}`)

      await this.getIngredients()
    }
  }

}
</script>
